import React from "react";
 

import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import imgStyles from "assets/style/image.js"
import { Link } from "@material-ui/core";

function CommunityPage() {
  const UseStyles = makeStyles(styles);
  const UseImgStyles = makeStyles(imgStyles);

  const classes = UseStyles();
  const imgClass = UseImgStyles();

  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          {community && <div style={{ color: 'black', padding: '50px', margin: '10vw auto' }} >
            <h2>Wspólnota {community.name}</h2>
            <img src={`/zdjecia/Wspólnoty/${community.name}.jpg`} alt="" style={{ maxWidth: '100%' }} />
            <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly' }}>
              <div >
                <img src={`/zdjecia/Księża/${community.priest}.jpg`} alt="" style={{ maxWidth: '80%', maxHeight: '200px', margin: '10% auto' }} />
                <h3 style={{ fontWeight: 'bold' }}>Opiekun: </h3>
                <h3>{community.priest}</h3>
              </div>
              <div >
                <img src={`/zdjecia/Liderzy/${community.lider}.jpg`} alt="" style={{ maxWidth: '80%', maxHeight: '200px', margin: '10% auto' }} />
                <h3 style={{ fontWeight: 'bold' }}>Lider: </h3>
                <h3>{community.lider}</h3>
              </div>
            </div>
            {community.meetings &&
              <>
                <h3 style={{ fontWeight: 'bold' }}>Spotkania: </h3>
                <h3>{community.meetings}</h3>
              </>
            }
            <h3 style={{ fontWeight: 'bold' }}>O wspólnocie: </h3>
            <h3>{community.description}</h3>
            {community.comming && community.comming.map((item, idx) => (
              <h3 key={idx}>{item}</h3>))}
            {community.news &&
              <>
                <h3>Aktualności: </h3>
                {community.news.map((item, idx) => (
                  <Link key={idx} href={`/${item.link}`} id={`#${item.link}`}>
                    <img src={`/${item.posterUrl}`} className={imgClass.img} alt={item.alt}/>
                  </Link>
                )
                )}
              </>
            }
          </div>}
        </div>
      </div>
    </Layout>
  );
}

 
  const community = {
    "url": "magnificat",
    "name": "MAGNIFICAT",
    "lider": "Ewa Sepioło",
    "priest": "ks. Andrzej Przybyła",
    "meetings": "Parafia pw. Wniebowzięcia NMP w Raciborzu, wtorek godz. 19:30",
    "description": "Pewnego wieczoru kilkoro ludzi, wśród nich siostra zakonna, pragnąc trwać w uwielbieniu Jezusa przyjętego w Eucharystii, spotkało się z ks. Waldemarem Chudalą na modlitwie i wywyższało Pana za Jego wielkie dzieła. Była to końcówka maja 1993 roku, w okolicach Święta Nawiedzenia Najświętszej Maryi Panny; zatem nowo powstała grupka przyjęła nazwę „Magnificat”, hymnu który Maryja wyśpiewała u św. Elżbiety. Trwali oni na cotygodniowych spotkaniach modlitewnych, umiłowaniu Pisma Świętego i pragnieniu świadczenia o Chrystusie w swoich środowiskach. Grupa rozrosła się i spotyka bez przerwy już ponad 25 lat. W ciągu tego czasu przeżywała fale wzrostu i spadku liczebnego. Obecnie około trzydziestu osób w różnym wieku. Na spotkaniach modlimy się, śpiewamy pieśni uwielbienia, rozważamy Słowo Boże przypadające na najbliższą niedzielę, słuchamy konferencji formacyjnej. Raz w miesiącu mamy wspólnotową Eucharystię. To głębokie spotkanie z Panem pobudza nas do apostolstwa w swym otoczeniu. Podejmujemy się wielu posług: prowadzenia nabożeństw w parafii i dekanacie, służba w raciborskim więzieniu, organizowania seminariów Odnowy Wiary, służby podczas mszy z modlitwą o uzdrowienie w Opolu, wywieszania plakatu na kościele – ewangelizacja wizualna, prowadzenia Kursów Alfa w parafii, raciborskim zakładzie karnym i pobliskich miejscowościach. W ciągu ostatnich dwóch lat angażujemy się w posługę przy rejonowych comiesięcznych Spotkaniach Rodzinnych. Naszym obecnym opiekunem jest ks. Andrzej Przybyła, proboszcz parafii Samborowice, a jednocześnie kapelan raciborskiego więzienia. Spotkania odbywają się we wtorki o godz. 19.30 w auli Domu Katechetycznego przy parafii Wniebowzięcia Najświętszej Maryi Panny.",
    "news": [
      {
        "link": "magnificat-aplha-2020",
        "posterUrl": "communitiesSlides/magnificat/magnificat-aplha-2020.jpg"
      },{
        "link": "magnificat-pulikowski-2020",
        "posterUrl": "communitiesSlides/magnificat/magnificat-pulikowski-2020.jpg"
      }
    ]
  }
   

export default CommunityPage;
